import {
  AcsQueryError, EbenGenericError, OrganisationQueryError,
  ProcessCancelError,
  ProcessesQueryError,
  ProcessGenericError,
  ProcessInstanceNotFoundError,
  ProcessQueryError,
  ProcessStartError,
  QueryGenericError,
  TaskClaimError,
  TaskCompleteError,
  TaskGenericError,
  TaskQueryError,
  TaskSaveError,
  TasksQueryError,
  TaskUnclaimError, UserQueryError,
} from '@alf-nx-workspace/eben/interfaces';

export class ProcessError {
  public static createFromError(error: Error, action: 'START' | 'QUERY', processDefinitionId: string): ProcessGenericError {
    switch (action) {
      case 'START':
        return new ProcessStartError(error, processDefinitionId);
      default:
        return new ProcessGenericError(error, processDefinitionId);
    }
  }
}

export class TaskError {
  public static createFromError(error: Error, action: 'SAVE' | 'COMPLETE' | 'CLAIM' | 'UNCLAIM', taskId: string): TaskGenericError {

    const errorKey = ErrorMessage.getErrorKey(error);

    switch (action) {
      case 'SAVE':
        return new TaskSaveError(error, errorKey, taskId);
      case 'COMPLETE':
        return new TaskCompleteError(error, errorKey, taskId);
      case 'CLAIM':
        return new TaskClaimError(error, errorKey, taskId);
      case 'UNCLAIM':
        return new TaskUnclaimError(error, errorKey, taskId);
      default:
        return new TaskGenericError(error, errorKey, taskId);
    }
  }
}

type QueryActionType = 'USER' | 'TASK' | 'TASKS' | 'PROCESS' | 'PROCESS-CANCEL' | 'PROCESSES' | 'ACS' | 'ORGANISATION';
export class QueryError {
  public static createFromError(error: Error, action: QueryActionType, data: unknown): QueryGenericError {
    switch (action) {
      case 'USER':
        return new UserQueryError(error, data);
      case 'TASK':
        return new TaskQueryError(error, data);
      case 'TASKS':
        return new TasksQueryError(error, data);
      case 'PROCESS':
        return new ProcessQueryError(error, data);
      case 'PROCESS-CANCEL':
        return new ProcessCancelError(error, data);
      case 'PROCESSES':
        return new ProcessesQueryError(error, data);
      case 'ACS':
        return new AcsQueryError(error, data);
      case 'ORGANISATION':
        return new OrganisationQueryError(error, data)
      default:
        return new QueryGenericError(error, data);
    }
  }
}

export class ErrorMessage {

  public static getErrorKey(error: unknown): string {
    let messageKey: string;
    try {
      const parsedError = JSON.parse(error as string);
      messageKey = parsedError.messageKey;
    } catch (error) {
      messageKey = 'GENERIC'
    }
    switch (messageKey) {
      case 'GENERAL.ERROR.FORBIDDEN':
        return 'FORBIDDEN';
      case 'GENERAL.ERROR.NOT-FOUND':
        return 'NOT-FOUND';
      default:
        return 'GENERIC';
    }
  }

  public static createSupportRequestInformationBlockFromError(error: EbenGenericError): string {
    if (error instanceof TaskGenericError) {
      return `TaskId: ${error.taskId}`;
    } else if (error instanceof ProcessGenericError) {
      if (error instanceof ProcessStartError) {
        return `ProcessDefinitionId: ${error.processDefinitionId}`;
      } else if (error instanceof ProcessInstanceNotFoundError) {
        return `ProcessInstanceId: ${error.processInstanceId}`;
      }
    } else if (error instanceof QueryGenericError) {
      if (error instanceof ProcessQueryError) {
        return `ProcessInstanceId: ${error.data}`;
      } else if (error instanceof ProcessCancelError) {
        return `ProcessInstanceId: ${error.data}`;
      } else if (error instanceof TaskQueryError) {
        return `TaskId: ${error.data}`;
      }
    }
    return '';
  }

  public static createMessageFromError(error: EbenGenericError): string {
    if (error instanceof QueryGenericError) {
      return this.handleGenericQueryError(error);
    } else if (error instanceof TaskGenericError) {
      return this.handleTaskGenericError(error);
    } else if (error instanceof ProcessGenericError) {
      return this.handleProcessGenericError(error);
    } else {
      return "UNKNOWN_ERROR";
    }
  }

  private static handleGenericQueryError(error: QueryGenericError): string {
    if (error instanceof TaskQueryError) {
      return "QUERY.ERROR.TASK";
    } else if (error instanceof TasksQueryError) {
      return "QUERY.ERROR.TASKS";
    } else if (error instanceof ProcessQueryError) {
      return "QUERY.ERROR.PROCESS";
    } else if (error instanceof ProcessCancelError) {
      return "QUERY.ERROR.PROCESS-CANCEL";
    } else if (error instanceof ProcessesQueryError) {
      return "QUERY.ERROR.PROCESSES";
    } else if (error instanceof AcsQueryError) {
      return "QUERY.ERROR.ACS";
    } else {
      return "QUERY.ERROR.GENERIC";
    }
  }

  private static handleTaskGenericError(error: TaskGenericError): string {
    if (error instanceof TaskSaveError) {
      return this.handleTaskSaveError(error);
    } else if (error instanceof TaskCompleteError) {
      return this.handleTaskCompleteError(error);
    } else if (error instanceof TaskClaimError) {
      return this.handleTaskClaimError(error);
    } else if (error instanceof TaskUnclaimError) {
      return this.handleTaskUnclaimError(error);
    } else {
      return "TASK.ERROR.GENERIC";
    }
  }

  private static handleProcessGenericError(
    error: ProcessGenericError
  ): string {
    if (error instanceof ProcessStartError) {
      return "PROCESS.ERROR.START.GENERIC";
    } else {
      return "PROCESS.ERROR.GENERIC";
    }
  }

  private static handleTaskSaveError(error: TaskSaveError): string {
    switch (error.errorKey) {
      case "FORBIDDEN":
        return "TASK.ERROR.SAVE.FORBIDDEN";
      case "NOT-FOUND":
        return "TASK.ERROR.SAVE.NOT-FOUND";
      default:
        return "TASK.ERROR.SAVE.GENERIC";
    }
  }

  private static handleTaskCompleteError(error: TaskCompleteError): string {
    switch (error.errorKey) {
      case "FORBIDDEN":
        return "TASK.ERROR.COMPLETE.FORBIDDEN";
      case "NOT-FOUND":
        return "TASK.ERROR.COMPLETE.NOT-FOUND";
      default:
        return "TASK.ERROR.COMPLETE.GENERIC";
    }
  }

  private static handleTaskClaimError(error: TaskClaimError): string {
    switch (error.errorKey) {
      case "FORBIDDEN":
        return "TASK.ERROR.CLAIM.FORBIDDEN";
      case "NOT-FOUND":
        return "TASK.ERROR.CLAIM.NOT-FOUND";
      default:
        return "TASK.ERROR.CLAIM.GENERIC";
    }
  }

  private static handleTaskUnclaimError(error: TaskUnclaimError): string {
    switch (error.errorKey) {
      case "FORBIDDEN":
        return "TASK.ERROR.UNCLAIM.FORBIDDEN";
      case "NOT-FOUND":
        return "TASK.ERROR.UNCLAIM.NOT-FOUND";
      default:
        return "TASK.ERROR.UNCLAIM.GENERIC";
    }
  }
}
