export interface Environment {
  production: boolean;

  // debug, log, error
  // debug  -> shows all
  // log    -> shows log and error
  // error  -> shows error only
  logLevel: LogLevel;
  hmr: boolean;
  groupAllowedToStartReiseauftragInRepresentation: string;
  validateSessionTimer: number;
  googleApiKey: string;
  showBannerBetween: [from: string, to: string];
  flowProcessConfig: string;
}

export enum LogLevel {
  all,
  log,
  error,
  off,
}
