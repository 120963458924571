import { ProcessInstanceQueryRepresentation } from '@alfresco/js-api';
import { PropertyOptionFilterModel } from "@alf-nx-workspace/shared/interfaces";

export enum FlowState {
  'running' = 'running',

  'completed' = 'completed',
  'completed_successful' = 'completed_successful',
  'completed_unsuccessful' = 'completed_unsuccessful',
  'cancelled' = 'cancelled',
  'all' = 'all',
}

export interface EbenProcessInstanceQueryRepresentation extends ProcessInstanceQueryRepresentation {
  syncKey?: string;
  orgEhKennung?: string;
  processDefinitionKey?: string;
  startedAfter?: string;
  startedBefore?: string;
  affectedUser?: number;
  flowState?: FlowState
  variables?: string[]
  variablesQuery?: string;
  userOptions?: PropertyOptionFilterModel[];
}

export const defaultEbenProcessInstanceQueryReprensentation: EbenProcessInstanceQueryRepresentation = {
  state: ProcessInstanceQueryRepresentation.StateEnum.Running,
  sort: ProcessInstanceQueryRepresentation.SortEnum.CreatedDesc,
  size: 25,
  page: 0,
  flowState: FlowState.all
}
