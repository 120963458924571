import { Select, Store } from '@ngxs/store';

import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LightGroupRepresentation, LightUserRepresentation, UserRepresentation } from '@alfresco/js-api';
import { AuthService } from './auth.service';
import { ClearAuthState, LoadOrganisationsEinheiten, LoadUser } from './auth.actions';
import { AuthState } from './auth.state';
import { EbenTaskDetail } from "@alf-nx-workspace/eben/interfaces";
import { map, switchMap } from "rxjs/operators";
import { Organisation } from '@alf-nx-workspace/shared/interfaces';

@Injectable({ providedIn: 'root' })
export class AuthFacade {

  public store = inject(Store);
  public authService = inject(AuthService);

  @Select(AuthState.user)
  public readonly user$: Observable<LightUserRepresentation>;

  @Select(AuthState.fullname)
  public readonly fullname$: Observable<string>;

  @Select(AuthState.organisations)
  public readonly organisationOptions$: Observable<Organisation[]>;

  public logout() {
    this.authService.logout();
    this.store.dispatch(new ClearAuthState()).pipe(
      map(() => this.store.selectSnapshot(AuthState))
    );
  }

  public loadUser(): Observable<UserRepresentation> {
    return this.store.dispatch(new LoadUser()).pipe(
      switchMap(() => this.store.select(AuthState.user))
    );
  }

  public loadOrganisationseinheiten():void {
    this.store.dispatch(new LoadOrganisationsEinheiten());
  }

  public getUser(): UserRepresentation {
    return this.store.selectSnapshot(AuthState.user);
  }

  public hasGroupPermission(groups: string[]): Observable<boolean> {
    return this.store.select(AuthState.hasGroupPermission(groups));
  }

  public isWorkflowAdminFor(processDefinitionKey: string): Observable<boolean> {
    return this.hasGroupPermission([`workflow.${processDefinitionKey}:process.view-any`]);
  }

  public isTaskAssignee(taskDetail: EbenTaskDetail): boolean {
    return this.store.selectSnapshot(AuthState.isTaskAssignee(taskDetail));
  }

  public isTaskCandidate(taskDetail: EbenTaskDetail): boolean {
    return taskDetail.memberOfCandidateGroup || taskDetail.memberOfCandidateUsers
  }

  public isTaskAdmin(taskDetail: EbenTaskDetail): boolean {
    const adminGroups = [`workflow.${taskDetail.processDefinitionKey}:process.view-any`];
    return this.store.selectSnapshot(AuthState.hasGroupPermission(adminGroups));
  }
}
