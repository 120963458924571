const PREFIX = 'auth';

export class LoadOrganisationsEinheiten {
  static readonly type = PREFIX + '.loadOrganisationseinheiten';
}

export class LoadUser {
  static readonly type = PREFIX + '.loadUser';

  constructor() {
  }
}

export class ClearAuthState {
  static readonly type = PREFIX + '.clearAuthState';
}
