import { Select, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { FlowProcessDefinitionModel, PropertyOptionFilterModel } from '@alf-nx-workspace/shared/interfaces';
import { FlowState } from './flow.state';
import { combineLatest, Observable, of } from 'rxjs';
import { EbenPagination, EbenProcessInstance, EbenProcessInstanceQueryRepresentation, EbenResultSetPaging, EbenTaskDetail, EbenTaskQueryRepresentation, } from '@alf-nx-workspace/eben/interfaces';
import { Resolve } from '@angular/router';
import {
  GetAdminWorkflows,
  GetAdminWorkflowsDefinitionKeys,
  GetFlowProcessDefinitions,
  GetMyDocuments,
  GetMyTasks,
  GetMyTasksTotal,
  GetMyWorkflows,
  GetMyWorkflowsDefinitionKeys,
  GetOrgEhWorkflows,
  GetOrgEhWorkflowsDefinitionKeys,
  ReloadScope,
  SetLanguage,
} from './flow.actions';
import { ProcessInstanceQueryRepresentation, SearchRequest } from '@alfresco/js-api';
import { ResultSetRowEntry } from '@alfresco/js-api/typings/src/api/search-rest-api/model/resultSetRowEntry';

@Injectable({
  providedIn: 'root',
})
export class FlowFacade implements Resolve<[FlowProcessDefinitionModel[], EbenPagination<EbenTaskDetail>]> {

  @Select(FlowState.language)
  language$: Observable<string>;

  @Select(FlowState.myTasksTotal)
  myTasksTotals$: Observable<number>;

  @Select(FlowState.flowProcessDefinitions)
  flowDefinitions$: Observable<FlowProcessDefinitionModel[]>;

  @Select(FlowState.myTasks)
  myTasks$: Observable<EbenPagination<EbenTaskDetail>>;

  @Select(FlowState.myWorkflowsQuery)
  myWorkflowsQuery$: Observable<EbenPagination<EbenProcessInstance>>;

  @Select(FlowState.myWorkflows)
  myWorkflows$: Observable<EbenPagination<EbenProcessInstance>>;

  @Select(FlowState.myWorkflowsDefinitionKeys)
  myWorkflowsDefinitionKeys$: Observable<string[]>;

  @Select(FlowState.orgEhWorkflows)
  orgehWorkflows$: Observable<EbenPagination<EbenProcessInstance>>;

  @Select(FlowState.orgEhWorkflowsDefinitionKeys)
  orgehWorkflowsDefinitionKeys$: Observable<string[]>;

  @Select(FlowState.adminWorkflows)
  adminWorkflows$: Observable<EbenPagination<EbenProcessInstance>>;

  @Select(FlowState.adminWorkflowsDefinitionKeys)
  adminWorkflowsDefinitionKeys$: Observable<string[]>;

  @Select(FlowState.scopeWorkflows)
  scopeWorkflows$: Observable<EbenPagination<EbenProcessInstance>>;

  @Select(FlowState.myDocuments)
  myDocuments$: Observable<EbenResultSetPaging>;

  public get workflowStates$(): Observable<PropertyOptionFilterModel[]> {
    return of([
      {
        id: ProcessInstanceQueryRepresentation.StateEnum.All,
        name: 'FILTER.PROCESS_ALL',
      },
      {
        id: ProcessInstanceQueryRepresentation.StateEnum.Running,
        name: 'FILTER.PROCESS_RUNNING',
      },
      {
        id: ProcessInstanceQueryRepresentation.StateEnum.Completed,
        name: 'FILTER.PROCESS_ENDED',
      },
    ] as PropertyOptionFilterModel[]);
  }

  constructor(public store: Store) {}

  public resolve(): Observable<[FlowProcessDefinitionModel[], EbenPagination<EbenTaskDetail>]> {
    return combineLatest([
      this.store.dispatch(new GetFlowProcessDefinitions()),
      this.store.dispatch(new GetMyTasksTotal()),
    ]);
  }

  public getFlowDefinitionByKey(processDefinitionKey: string) {
    return this.store.select(FlowState.flowDefinitionByKey(processDefinitionKey));
  }

  public setLanguage(language: string) {
    return this.store.dispatch(new SetLanguage(language));
  }

  public getMyTasks(query: EbenTaskQueryRepresentation) {
    this.store.dispatch(new GetMyTasks(query));
  }

  public getMyWorkflows(query: EbenProcessInstanceQueryRepresentation) {
    this.store.dispatch(new GetMyWorkflows(query));
  }

  public getMyWorkflowDefinitionKeys() {
    this.store.dispatch(new GetMyWorkflowsDefinitionKeys());
  }

  public getMyDocuments(query: SearchRequest) {
    this.store.dispatch(new GetMyDocuments(query));
  }

  public getOrgEhWorkflows(query: EbenProcessInstanceQueryRepresentation) {
    this.store.dispatch(new GetOrgEhWorkflows(query));
  }

  public getOrgEhWorkflowDefinitionKeys() {
    this.store.dispatch(new GetOrgEhWorkflowsDefinitionKeys());
  }

  public getAdminWorkflows(query: EbenProcessInstanceQueryRepresentation) {
    this.store.dispatch(new GetAdminWorkflows(query));
  }

  public getAdminWorkflowDefinitionKeys() {
    this.store.dispatch(new GetAdminWorkflowsDefinitionKeys());
  }

  public getWorkflowDocuments(processInstanceIds: string[]): Observable<ResultSetRowEntry[]> {
    return this.store.select(FlowState.workflowDocumentsByProcessInstanceIds(processInstanceIds));
  }

  public reloadScope() {
    this.store.dispatch(new ReloadScope());
  }

  public reloadTasksCounter() {
    this.store.dispatch(new GetMyTasksTotal());
  }

}
