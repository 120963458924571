import { EbenGenericError } from "./eben-error.model";

export class QueryGenericError extends EbenGenericError {
  constructor(public error: Error, public data: unknown) {
    super();
  }
}

export class UserQueryError extends QueryGenericError {
}

export class TaskQueryError extends QueryGenericError {
}

export class TasksQueryError extends QueryGenericError {
}

export class ProcessQueryError extends QueryGenericError {
}

export class ProcessCancelError extends QueryGenericError {
}

export class ProcessesQueryError extends QueryGenericError {
}

export class AcsQueryError extends QueryGenericError {
}

export class OrganisationQueryError extends QueryGenericError {
}
