import { inject, Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { GroupRepresentation, UserRepresentation } from '@alfresco/js-api';
import { ClearAuthState, LoadOrganisationsEinheiten, LoadUser } from './auth.actions';
import { EbenTaskDetail } from '@alf-nx-workspace/eben/interfaces';
import { AuthService } from './auth.service';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Organisation } from '@alf-nx-workspace/shared/interfaces';
import { BpmService } from '@alf-nx-workspace/shared/utils';

export interface AuthStateModel {
  user: UserRepresentation;
  username: string;
  organisations: Organisation[];
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    user: null,
    username: '',
    organisations: []
  }
})
@Injectable()
export class AuthState {

  readonly bpmService = inject(BpmService);
  readonly authService = inject(AuthService);

  @Selector()
  public static user(state: AuthStateModel): UserRepresentation {
    return state.user;
  }

  @Selector()
  public static groups(state: AuthStateModel): GroupRepresentation[] {
    return state.user?.groups;
  }

  @Selector()
  public static groupNames(state: AuthStateModel): string {
    return state.user?.groups.map((group: GroupRepresentation) => group.name).join('\r\n');
  }

  @Selector()
  public static username(state: AuthStateModel): string {
    return state.username;
  }

  @Selector()
  public static fullname(state: AuthStateModel): string {
    return state.user.fullname;
  }

  @Selector()
  public static organisations(state: AuthStateModel): Organisation[] {
    return state.organisations;
  }

  static hasGroupPermission(groups: string[]) {
    return createSelector([AuthState], (state: AuthStateModel) => {
      if (groups === null || groups === undefined || groups.length === 0) {
        return true;
      }
      if (state.user) {
        return groups.some((group: string) => {
          if (!state.user.groups) return false;
          return state.user.groups.some((groupRepresentation: GroupRepresentation) => {
            // found wildcard in group
            if (group.startsWith('%') && group.endsWith('%')) {
              return new RegExp(group.replace(/%/g, ''), 'g').test(groupRepresentation.name);
            }
            return groupRepresentation.name === group;
          });
        });
      }
      return false;
    });
  }

  static isTaskAssignee(taskDetail: EbenTaskDetail) {
    return createSelector([AuthState], (state: AuthStateModel) => {
      return state.user.id === taskDetail.assignee.id;
    });
  }


  @Action(LoadUser)
  protected loadUser(ctx: StateContext<AuthStateModel>, action: LoadUser): Observable<UserRepresentation> {
    return this.authService.loadUser().pipe(
      tap((user: UserRepresentation) => {
          ctx.patchState({
            user: user
          });
        }
      )
    );
  }

  @Action(ClearAuthState)
  protected clearAuthState(ctx: StateContext<AuthStateModel>, action: ClearAuthState) {
    ctx.patchState({
      user: null,
      username: ''
    });
  }

  @Action(LoadOrganisationsEinheiten)
  protected loadOrganisationen(ctx: StateContext<AuthStateModel>, action: LoadOrganisationsEinheiten) {
    return this.bpmService.loadOrganisationen().pipe(
      tap((organisations: Organisation[]) => {
        ctx.patchState({organisations})
      })
    )
  }
}
