import { ProcessInstanceQueryRepresentation, QueryBody, TaskQueryRepresentation } from '@alfresco/js-api';
import { EbenProcessInstance } from "@alf-nx-workspace/eben/interfaces";

const PREFIX = 'flow';

export class SetLanguage {
  static readonly type = PREFIX + '.setLanguage';

  constructor(public language: string) {}
}

export class GetFlowProcessDefinitions {
  static readonly type = PREFIX + '.getFlowProcessDefinitions';
}

export class GetMyTasksTotal {
  static readonly type = PREFIX + '.getAssignedTotalTasks';

  constructor(public query?: TaskQueryRepresentation) {}
}

export class GetMyTasks {
  static readonly type = PREFIX + '.getMyTasks';

  constructor(public query: TaskQueryRepresentation) {}
}

export class GetMyWorkflows {
  static readonly type = PREFIX + '.getMyWorkflows';

  constructor(public query: ProcessInstanceQueryRepresentation) {}
}

export class GetMyWorkflowsDefinitionKeys {
  static readonly type = PREFIX + '.getMyWorkflowsDefinitionKeys';

  constructor() {}
}

export class GetOrgEhWorkflows {
  static readonly type = PREFIX + '.getOrgEhWorkflows';

  constructor(public query: ProcessInstanceQueryRepresentation) {}
}

export class GetOrgEhWorkflowsDefinitionKeys {
  static readonly type = PREFIX + '.getOrgEhWorkflowsDefinitionKeys';

  constructor() {}
}

export class GetAdminWorkflows {
  static readonly type = PREFIX + '.getAdminWorkflows';

  constructor(public query: ProcessInstanceQueryRepresentation) {}
}

export class GetAdminWorkflowsDefinitionKeys {
  static readonly type = PREFIX + '.getAdminWorkflowsDefinitionKeys';

  constructor() {}
}

export class GetWorkflowDocuments {
  static readonly type = PREFIX + '.getWorkflowDocuments';

  public processInstanceIds: string[] = [];

  constructor(processInstances: EbenProcessInstance[]) {
    const processInstanceIds: string[] = [];
    processInstances.forEach((processInstance) => {
      this.processInstanceIds.push(processInstance.id);
      this.processInstanceIds.push(...processInstance.subProcessInstanceIds);
    })
  }
}

export class GetMyDocuments {
  static readonly type = PREFIX + '.getMyDocuments';

  constructor(public query: QueryBody) {}
}

export class ReloadScope {
  static readonly type = PREFIX + '.reloadScope';
}
